import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Layout from '../components/common/Layout'
import { Zoom, Fade } from 'react-reveal'
import { Container, Row, Col } from 'reactstrap'
import Section from '../components/common/Section'
import Repository from '../components/common/CodingChallenge/Repository'
import Environments from '../components/common/CodingChallenge/Environments'
import Challenges from '../components/common/CodingChallenge/Challenges'
import Intro from '../components/common/CodingChallenge/Intro'

import imgChallengers from '../components/2019/media/stock/challengers.jpg'
import cactus2 from '../components/2019/media/stock/cactus2.jpg'
import coding_earth_mockup from '../components/2019/media/coding_earth_mockup.png'

import { faGithub } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class CodingChallenge extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      commits: [],
      platformEnvironments: [],
    }
  }

  componentDidMount() {
    fetch(process.env.GATSBY_CODINGCHALLENGE_API_URL).then(response => {
      response.json().then(json => {
        this.setState({
          commits: json.github.slice(0, 20),
          platformEnvironments: json.platformsh,
        })
      })
    })
  }

  render() {
    const content = {}
    this.props.data.allMarkdownRemark.edges.forEach(e => {
      content[e.node.frontmatter.slug] = e.node.html
    })

    return (
      <Layout>
        <Section section="section">
          <header />
        </Section>

        <Intro />

        <Section>
          <Fade left distance="20px">
            <div
              dangerouslySetInnerHTML={{
                __html: content['coding-challenge-whatis'],
              }}
            />
          </Fade>

          <Row>
            <Col md={8}>
              <Fade left distance="20px">
                <div
                  dangerouslySetInnerHTML={{
                    __html: content['coding-challenge-build'],
                  }}
                />
              </Fade>
            </Col>
            <Col
              md={4}
              className=" d-flex  align-items-center justify-content-center"
            >
              <a
                href="https://github.com/coding-berlin/devday-codingchallenge"
                target="_blank"
              >
                <FontAwesomeIcon icon={faGithub} size="8x" />
              </a>
            </Col>
          </Row>
          <div className="py-5">
            <a href="https://devday.tk" target="_blank">
              <img src={coding_earth_mockup} />
            </a>
          </div>
        </Section>
        <Section
          section="cta cta--darken cta--tertiary"
          bg={
            'https://res.cloudinary.com/turbinekreuzberg/image/upload/v1529574086/devday18/berlin/IMG_2973.jpg'
          }
        >
          <Fade left distance="20px">
            <div
              dangerouslySetInnerHTML={{
                __html: content['coding-challenge-participate'],
              }}
            />
          </Fade>
        </Section>

        <Section>
          <h2 className="mb-5">Here are our challenge ideas</h2>
          <Challenges />
        </Section>

        <Section section="cta cta--darken cta--primary" bg={imgChallengers}>
          <Row>
            <Col md={8}>
              <Fade left distance="20px">
                <div
                  dangerouslySetInnerHTML={{
                    __html: content['coding-challenge-winners'],
                  }}
                />
              </Fade>
            </Col>
            <Col md={4}>
              <img src={cactus2} />
            </Col>
          </Row>
        </Section>

        <Section>
          <Row>
            <Col>
              <h4>Latest commits</h4>
              <Repository commits={this.state.commits} />
            </Col>
            <Col>
              <h4>Active platform.sh environments</h4>
              <Environments environments={this.state.platformEnvironments} />
            </Col>
          </Row>
        </Section>
      </Layout>
    )
  }
}

export const query = graphql`
  {
    allMarkdownRemark(
      filter: { frontmatter: { slug: { regex: "/^coding-challenge(.*)/" } } }
    ) {
      edges {
        node {
          html
          frontmatter {
            slug
            title
          }
        }
      }
    }
  }
`
