import React from 'react'

export default ({ environments }) => (
  <div>
    {environments.map(env => {
      return (
        <div key={`env-${env.name}`}>
          <h6>{env.title}</h6>
        </div>
      )
    })}
  </div>
)
