import React from 'react'
import * as ServiceIcon from '../ServiceIcon'
import {
  faNewspaper,
  faCalendarAlt,
  faLaughSquint,
  faHashtag,
  faSearch,
  faEnvelope,
  faPassport,
} from '@fortawesome/free-solid-svg-icons'

import {
  faMeetup,
  faYoutube,
  faTwitter,
  faGithub,
  faSlideshare,
} from '@fortawesome/free-brands-svg-icons'

export default () => (
  <ServiceIcon.ServiceIconList>
    <ServiceIcon.ServiceIcon
      icon={faNewspaper}
      size={6}
      title="create a news feed, powered by arbitrary RSS feeds"
    />
    <ServiceIcon.ServiceIcon
      icon={faCalendarAlt}
      size={6}
      title="parse ICS files and collect event dates and details"
    />
    <ServiceIcon.ServiceIcon
      icon={faMeetup}
      size={6}
      title="read the latest meetups from an API (meetup.com)"
    />
    <ServiceIcon.ServiceIcon
      icon={faSlideshare}
      size={6}
      title="create a slideshare integration"
    />
    <ServiceIcon.ServiceIcon
      icon={faYoutube}
      size={6}
      title="create a youtube integration with latest conference talks"
    />
    <ServiceIcon.ServiceIcon
      icon={faLaughSquint}
      size={6}
      title="create an xkcd viewer that displays the cartoon of the day"
    />
    <ServiceIcon.ServiceIcon
      icon={faTwitter}
      size={6}
      title="read tweets related to a certain topic"
    />
    <ServiceIcon.ServiceIcon
      icon={faGithub}
      size={6}
      title="create a gist browser"
    />
    <ServiceIcon.ServiceIcon
      icon={faHashtag}
      size={6}
      title="build a tag system that can be used by any micro service"
    />
    <ServiceIcon.ServiceIcon
      icon={faSearch}
      size={6}
      title="create a search index to search for arbitrary content of any micro service"
    />
    <ServiceIcon.ServiceIcon
      color="red"
      icon={faPassport}
      size={6}
      title="Build a login option for the whole site using Jolocom or Blockstack"
    />
    <ServiceIcon.ServiceIcon
      color="red"
      icon={faEnvelope}
      size={6}
      title="Integrate a Twilio inbound messaging channel to add content"
    />
  </ServiceIcon.ServiceIconList>
)
