import React from 'react'
import { Fade } from 'react-reveal'
import { Container, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'gatsby'

const ServiceIcon = props => (
  <Col lg={props.size || 6}>
    <Fade right distance="20px">
      <div className={`service-icon__item ${props.color}`}>
        <Row className="align-items-center">
          <Col xs="auto">
            <FontAwesomeIcon icon={props.icon} size="2x" />
          </Col>
          <Col>
            {props.href ? (
              <h6>
                <Link to={props.href} style={{ color: 'white' }}>
                  {props.title}
                </Link>
              </h6>
            ) : (
              <h6>{props.title}</h6>
            )}
          </Col>
        </Row>
      </div>
    </Fade>
  </Col>
)

const ServiceIconList = props => (
  <div className="service-icon">
    <div className="service-icon__background" />
    <div className="service-icon__copy">
      <Row>{props.children}</Row>
    </div>
  </div>
)

export { ServiceIcon, ServiceIconList }
